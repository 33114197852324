<template>
  <div>
    <span class="mr-3 text-indigo-900">Mois</span>
    <div class="w-24 lg:w-32 mr-3">
      <multiselect
        :value="value[fields[0]]"
        track-by="value"
        label="label"
        :options="monthOptions"
        :searchable="false"
        placeholder=""
        select-label=""
        deselect-label=""
        selected-label=""
        :allow-empty="false"
        @input="handleInput(0, $event)"
      />
    </div>
    <span class="mr-3 text-gray-400">et</span>
    <span class="mr-3 text-indigo-900">Année</span>
    <div class="w-24 lg:w-28">
      <multiselect
        :value="value[fields[1]]"
        track-by="value"
        label="label"
        :options="yearOptions"
        :searchable="false"
        placeholder=""
        select-label=""
        deselect-label=""
        selected-label=""
        :allow-empty="false"
        @input="handleInput(1, $event)"
      />
    </div>
  </div>
</template>

<script>
const MONTHS = [
  { label: "Janvier", value: 1 },
  { label: "Février", value: 2 },
  { label: "Mars", value: 3 },
  { label: "Avril", value: 4 },
  { label: "Mai", value: 5 },
  { label: "Juin", value: 6 },
  { label: "Juillet", value: 7 },
  { label: "Aout", value: 8 },
  { label: "Septembre", value: 9 },
  { label: "Octobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Décembre", value: 12 },
];

const OLDEST_YEAR = 2021;

export default {
  name: "MonthYearFilter",
  props: {
    value: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      monthOptions: MONTHS,
    };
  },
  computed: {
    yearOptions() {
      const options = [];
      for (let i = OLDEST_YEAR; i <= new Date().getFullYear(); i++) {
        options.push({ label: i, value: i });
      }
      return options;
    },
  },
  created() {
    if (this.value[this.fields[0]].value && this.value[this.fields[1]].value) {
      return;
    }
    const currentDate = new Date();
    const currentMonth = MONTHS[currentDate.getMonth()];
    this.$emit("input", { field: this.fields[0], value: currentMonth });
    const currentYear = {
      label: currentDate.getFullYear(),
      value: currentDate.getFullYear(),
    };
    setTimeout(() => {
      this.$emit("input", { field: this.fields[1], value: currentYear });
    });
  },
  methods: {
    handleInput(fieldIndex, value) {
      this.$emit("input", { field: this.fields[fieldIndex], value });
    },
  },
};
</script>
